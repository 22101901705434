import React, { useState } from 'react'
import useDarkSide from './useDarkSide.js'
import { DarkModeSwitch } from 'react-toggle-dark-mode'

const Switcher = () => {
  const [colorTheme, setTheme] = useDarkSide()
  const [darkSide, setDarkSide] = useState(
    colorTheme === 'light' ? true : false
  )
  const toggleDarkMode = (checked) => {
    setTheme(colorTheme)
    setDarkSide(checked)
  }

  return (
    <nav className="z-50 fixed bottom-8 right-8 inline-block">
      <DarkModeSwitch checked={darkSide} onChange={toggleDarkMode} />
    </nav>
  )
}

export default Switcher
